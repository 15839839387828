var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block", class: { disabled: _vm.disabled } },
    [
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: true,
                expression: "true",
              },
            ],
            attrs: {
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(0, 0, 0, 0)",
            },
          })
        : _c(
            "el-popconfirm",
            {
              attrs: {
                "confirm-button-text": "OK",
                "cancel-button-text": "No, thanks",
                icon: "el-icon-info",
                "icon-color": "red",
                title: "Are you sure to delete this?",
              },
              on: { confirm: _vm.deleteSlot },
            },
            [
              _c(
                "div",
                {
                  staticClass: "content",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("div", { staticClass: "info" }, [
                    _c("span", [
                      _c("strong", [_vm._v(_vm._s(_vm.project.ref))]),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.component.name))]),
                  ]),
                  _vm.object.time_estimate
                    ? _c("span", { staticClass: "time" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("seconds2duration")(
                                _vm.object.time_estimate
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }