var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "actions-buttons",
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.tabChanged },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "Timeline", name: "_timeline" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "Calendar", name: "_calendar" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "Schedule", name: "_schedule" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide", mode: "out-in" } },
        [
          _vm.activeTab == "_timeline"
            ? _c("PlannerTab_timeline", { key: "timeline" })
            : _vm.activeTab == "_calendar"
            ? _c("PlannerTab_calendar", { key: "calendar" })
            : _vm.activeTab == "_schedule"
            ? _c("PlannerTab_schedule", { key: "schedule" })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }