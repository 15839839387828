var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "week-selector" }, [
        _c("div", { staticClass: "users-col" }, [
          _c("span", [
            _vm._v(
              " Day " +
                _vm._s(_vm.cursor.weekday() + 1) +
                " - #" +
                _vm._s(_vm.cursor.isoWeek()) +
                " "
            ),
          ]),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.moveCursor(-1)
                },
              },
            },
            [
              _c("svgicon", {
                staticClass: "triangle",
                attrs: { name: "triangle" },
              }),
            ],
            1
          ),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.moveCursor(1)
                },
              },
            },
            [
              _c("svgicon", {
                staticClass: "triangle",
                attrs: { name: "triangle" },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "options" },
          [
            !_vm.moment().isSame(_vm.cursor, "day")
              ? _c(
                  "el-button",
                  {
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        return _vm.resetCursor()
                      },
                    },
                  },
                  [_vm._v(" Today ")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                staticClass: "edit-button",
                attrs: { size: "mini" },
                on: {
                  click: function ($event) {
                    _vm.editing = !_vm.editing
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.editing ? "Done" : "Edit") + " ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "week-days" },
        [
          _c("div", { staticClass: "users-col" }, [
            _c("div", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "spinner",
              attrs: {
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(0, 0, 0, 0)",
              },
            }),
          ]),
          _vm._l(_vm.days, function (day) {
            return _c(
              "div",
              {
                key: day.format("DD"),
                staticClass: "day-col",
                class: { today: day.isSame(_vm.moment(), "day") },
              },
              [
                _c("span", [
                  _vm._v(" " + _vm._s(day.format("dddd")) + " "),
                  _c("strong", [_vm._v(_vm._s(day.format("DD")))]),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "rows" },
      _vm._l(_vm.users, function (user) {
        return _c(
          "div",
          {
            key: user.id,
            staticClass: "user-row",
            class: {
              "my-row": user.id === _vm.current_user.id,
              editing: _vm.editing,
            },
          },
          [
            _c("div", { staticClass: "users-col" }, [
              _c("span", [_vm._v(_vm._s(user.name))]),
            ]),
            _vm._l(_vm.days, function (day) {
              return _c(
                "div",
                { key: day.toString(), staticClass: "day-col" },
                [
                  _c("day-bar-block", {
                    attrs: {
                      slots: _vm.filterSlots(day, user),
                      day: day,
                      user: user,
                    },
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }