var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "calendar-view",
      class: { "padding-tracking-bar": _vm.active_session },
    },
    [_c("planner-yearly-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }