var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "calendar-view",
      class: { "padding-tracking-bar": _vm.active_session },
    },
    [
      _c(
        "el-radio-group",
        {
          attrs: { size: "small" },
          model: {
            value: _vm.view_type,
            callback: function ($$v) {
              _vm.view_type = $$v
            },
            expression: "view_type",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: 0 } }, [_vm._v("Daily")]),
          _c("el-radio-button", { attrs: { label: 1 } }, [_vm._v("Weekly")]),
        ],
        1
      ),
      _vm.view_type === _vm.CALENDAR_VIEW_TYPE.DAILY
        ? _c("planner-daily-view", {
            attrs: {
              days: _vm.days,
              slots: _vm.slots,
              loading: _vm.loading,
              cursor: _vm.cursor,
            },
            on: { moveCursor: _vm.moveCursor, resetCursor: _vm.resetCursor },
          })
        : _vm.view_type === _vm.CALENDAR_VIEW_TYPE.WEEKLY
        ? _c("planner-weekly-view", {
            attrs: {
              days: _vm.days,
              slots: _vm.slots,
              loading: _vm.loading,
              cursor: _vm.cursor,
            },
            on: { moveCursor: _vm.moveCursor, resetCursor: _vm.resetCursor },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }