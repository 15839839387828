var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "bar" },
      [
        _vm.loading
          ? _c("bar-segment", {
              key: "loading_bar",
              attrs: {
                time: 0,
                total_time: _vm.full_time,
                height: 20,
                index: 200,
                disabled: "",
                background_color: "#0f263b",
                show_time: false,
              },
            })
          : [
              _vm._l(_vm.data, function (item) {
                return _c(
                  "bar-segment",
                  {
                    key: item.project.id,
                    attrs: {
                      time: item.time_full,
                      total_time: _vm.full_time,
                      height: 20,
                      index: 200,
                      disabled: "",
                      background_color: "#0f263b",
                      show_time: false,
                      show_bracket: "",
                      bracket_label: item.project.ref,
                    },
                  },
                  [
                    item.time_spent
                      ? _c("bar-segment", {
                          key: `${item.project.id}_tspent`,
                          attrs: {
                            time: item.time_spent,
                            total_time: item.time_full,
                            height: 20,
                            index: 200,
                            disabled: "",
                            background_color: item.time_over
                              ? `#f92139`
                              : `#69ebbd`,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm.data.length > 1
                ? _c("bar-segment", {
                    key: "project_swap",
                    attrs: {
                      time: 5 * 60 * 1000 * (_vm.data.length - 1),
                      total_time: _vm.full_time,
                      height: 20,
                      index: 200,
                      disabled: "",
                      background_color: "#ffc567",
                    },
                  })
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }