var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "timeline-container" },
    [_c("project-timeline")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }