var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.slots
        ? _vm._l(_vm.slots, function (slot) {
            return _c("slot-block", { key: slot.id, attrs: { object: slot } })
          })
        : _vm._e(),
      _vm.editing && _vm.time_allocated < 21600
        ? _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  _vm.show_selector = true
                },
              },
            },
            [_vm._v(" + ")]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.show_selector,
            width: "60%",
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show_selector = $event
            },
            closed: _vm.onCloseDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-content" },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _vm.selection_step == 0
                    ? _c("project-selector", {
                        on: {
                          onSelection: (value) => {
                            _vm.selected_project = value
                            _vm.selection_step++
                          },
                        },
                      })
                    : _vm.selection_step == 1
                    ? _c("stage-selector", {
                        attrs: { selected_project: _vm.selected_project },
                        on: {
                          onBack: function ($event) {
                            _vm.selection_step--
                          },
                          onSelection: (value) => {
                            // The selected object it's a component, not a stage
                            if (value.stage) {
                              _vm.selected_stage = null
                              _vm.selected_component = value
                              _vm.selection_step += 2
                            } else {
                              _vm.selected_stage = value
                              _vm.selection_step++
                            }
                          },
                        },
                      })
                    : _vm.selection_step == 2
                    ? _c("component-selector", {
                        attrs: {
                          selected_project: _vm.selected_project,
                          selected_stage: _vm.selected_stage,
                        },
                        on: {
                          onBack: function ($event) {
                            _vm.selection_step--
                          },
                          onSelection: (value) => {
                            _vm.selected_component = value
                            _vm.selection_step++
                          },
                        },
                      })
                    : _vm.selection_step == 3 && _vm.selected_component
                    ? _c(
                        "div",
                        [
                          _c("h2", [_vm._v("Time estimate:")]),
                          _c("hr"),
                          _c(
                            "div",
                            {
                              staticClass: "back",
                              on: {
                                click: () => {
                                  if (_vm.selected_stage) _vm.selection_step--
                                  else _vm.selection_step -= 2
                                },
                              },
                            },
                            [
                              _c("svgicon", {
                                staticClass: "triangle",
                                attrs: { name: "triangle" },
                              }),
                              _c("label", [
                                _vm._v("BACK "),
                                _c("span", [_vm._v("/")]),
                              ]),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.selected_project.name) +
                                    " " +
                                    _vm._s(
                                      _vm.selected_stage
                                        ? `/ Stage # ${_vm.selected_stage.number}`
                                        : null
                                    ) +
                                    " / " +
                                    _vm._s(_vm.selected_component.name) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c("hr"),
                          _c(
                            "div",
                            { staticClass: "slider-container" },
                            [
                              _c("span", [
                                _vm._v(" Slots: "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.slots.length)),
                                ]),
                              ]),
                              _c("span", [
                                _vm._v(" Total time allocated: "),
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("seconds2duration")(
                                          _vm.time_allocated + _vm.time_estimate
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm._v(
                                  " +" +
                                    _vm._s(
                                      _vm._f("seconds2duration")(
                                        _vm.time_estimate
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("hr"),
                              _c("el-slider", {
                                attrs: {
                                  step: 900,
                                  marks: _vm.marks,
                                  min: 0,
                                  max: _vm.daily_hours - _vm.time_allocated,
                                  "show-tooltip": false,
                                },
                                model: {
                                  value: _vm.time_estimate,
                                  callback: function ($$v) {
                                    _vm.time_estimate = $$v
                                  },
                                  expression: "time_estimate",
                                },
                              }),
                              _c("text-editor", {
                                ref: "editor",
                                attrs: {
                                  content: _vm.slot_notes,
                                  placeholder: "Add notes here…",
                                },
                                on: {
                                  "update:content": function ($event) {
                                    _vm.slot_notes = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "create",
                              on: { click: _vm.createSlot },
                            },
                            [_vm._v(" Create ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }